<template>
  <div>
    <ReportsCard 
      :headers="reporteeHeaders"
      hasLoadedGetter="report/HasReporteeReportsPageLoaded"
      itemCountGetter="report/GetReporteeReportCount"
      itemGetter="report/GetReporteeReports"
      itemLoadGetter="report/loadReporteeReports"
      cardTitle="Reports made against me"
      refreshEvent="report/reloadReporteeReportsPages"
      :showId="false"
      :allowExpand="true"
      :allowManagement="false"
    />
    <ReportsCard 
      :headers="reporterHeaders"
      hasLoadedGetter="report/HasReporterReportsPageLoaded"
      itemCountGetter="report/GetReporterReportCount"
      itemGetter="report/GetReporterReports"
      itemLoadGetter="report/loadReporterReports"
      refreshEvent="report/reloadReporterReportsPages"
      cardTitle="Reports made by me"
      :showId="false"
      :allowExpand="true"
      :allowManagement="false"
    />
  </div>
</template>

<script>
import ReportsCard from '@/views/components/report/ReportsCard.vue'

export default {
  components: {
    ReportsCard,
  },
  data() {
    return {
      reporteeHeaders: [
        { text: 'Report open time', value: 'timestamp' },
        { text: 'Report status', value: 'report_status' },
        { text: '', value: 'appeal' },
      ],
      reporterHeaders: [
        { text: 'Report open time', value: 'timestamp' },
        { text: 'Report status', value: 'report_status' },
        { text: 'Reported player', value: 'reportee' },
        { text: '', value: 'appeal' },
      ],
    }
  },
}
</script>
